@use 'colors' as *;
@use '@angular/material' as mat;
@import url('https://cdn.easi-training.fr/assets/css/icons.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic,600,700');

@include mat.elevation-classes();
@include mat.app-background();

html,
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: $grey1;
    font-size: 14px;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    z-index: -1;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
    iframe {
        border: none;
    }
}

input:focus,
textarea:focus {
    outline: none !important;
}

.easi-header {
    display: none;
    height: 50px;
    z-index: 102;
    position: relative;
}

.easi-iframe {
    height: 60px;
    width: 100%;
    border: none;
    display: block;
    position: relative;
    z-index: 103;
}

app-flash-message {
    width: 100vw;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 8;
}

.ngx-bar {
    top: 50px !important;
    background: #ff3366 !important;
}

#loading-bar .bar {
    position: relative !important;
}

.hidden {
    display: none;
}

.description-in-tooltip {
    display: -webkit-box;
    line-clamp: 10;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
    text-overflow: ellipsis;
}

/********** ICONS **********/

.icon-parcours-cercle,
.icon-parcours {
    color: $parcours;
}
.icon-bloc-cercle,
.icon-bloc {
    color: $bloc;
}
.icon-competence-cercle,
.icon-competence {
    color: $competence;
}
.icon-module-cercle,
.icon-module {
    color: $module;
}
.icon-sequence-cercle,
.icon-sequence {
    color: $sequence;
}
.icon-activite-cercle,
.icon-ressource-cercle,
.icon-Preparation-cercle,
.icon-remise-devoir,
.icon-activite,
.icon-ressource,
.icon-Preparation,
.icon-devoir {
    color: $activity;
}
.icon-activite-presentielle-cercle,
.icon-TP-cercle,
.icon-lien-cercle,
.icon-activite-presentielle,
.icon-TP,
.icon-lien {
    color: $green1;
}
.icon-evaluation-cercle,
.icon-positionnement-cercle,
.icon-evaluation,
.icon-positionnement {
    color: $green3;
}
.icon-Corrige-cercle,
.icon-Corrige {
    color: $corrige;
}
.icon-Miseenapplcation-cercle,
.icon-Miseenapplcation {
    color: $miseappli;
}

app-student-panel {
    height: 100%;
}

#left-content,
#right-content {
    &.reduced {
        .tab.left {
            display: none;
        }
        .tab.right .label {
            display: none;
        }
        *:not(.icon-minimizebloc) {
            visibility: hidden;
        }
        .icon-minimizebloc {
            visibility: visible;
        }
    }
}

.mdc-switch__handle {
    margin: 0 !important;
}

/********** DATEPICKER **********/

.mat-datepicker-content {
    box-shadow:
        0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.87);
}
.mat-calendar {
    .mat-calendar-header {
        .mat-button-base {
            background: 0 0;
        }
    }
    .mat-calendar-table {
        .mat-calendar-table-header {
            color: rgba(0, 0, 0, 0.38);
        }
        .mat-calendar-body-cell-content {
            border-color: transparent;
            &.mat-calendar-body-today:not(.mat-calendar-body-selected) {
                border-color: rgba(0, 0, 0, 0.38);
            }
            &.mat-calendar-body-selected {
                background-color: $blue1;
                color: #fff;
            }
        }
    }
}

/********** EXPANSION **********/

.mat-expansion-panel {
    border-radius: 0px !important;
    &.dndDragover {
        & > .mat-expansion-panel-header {
            .dragover-message {
                opacity: 1 !important;
            }
        }
    }
}

.mat-expansion-panel-header {
    padding: 0px !important;
    &:hover {
        background: initial !important;
    }
    .mat-expansion-indicator {
        &::after {
            color: inherit !important;
        }
    }
}

.mat-expansion-panel-body {
    padding: 0px 0px 0px 10px !important;
}

.mat-expansion-indicator {
    color: white;
    position: relative;
    right: 20px;
}

.facultative-container {
    .mat-expansion-indicator {
        color: $blue1;
    }
}

/********** DIALOGS **********/

.cdk-overlay-backdrop {
    z-index: 999 !important;
}

.cdk-global-overlay-wrapper {
    pointer-events: auto !important;
    overflow: auto !important;
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
    --mat-dialog-container-max-width: 100vw;
}

.mat-mdc-dialog-container {
    margin: 0 !important;
    padding: 0 !important;
    overflow: visible !important;
    .mdc-dialog__surface {
        overflow: visible !important;
        .mat-mdc-dialog-content {
            letter-spacing: unset !important;
            line-height: unset !important;
            font-size: unset !important;
            font-weight: unset !important;
        }
    }
}

.mat-calendar-previous-button::after,
.mat-calendar-next-button::after {
    margin: 19.5px !important;
}

/********** TOGGLE ************/
.mdc-switch {
    --mdc-switch-disabled-handle-opacity: 0.38;
    --mdc-switch-disabled-selected-icon-opacity: 0.38;
    --mdc-switch-disabled-track-opacity: 0.12;
    --mdc-switch-disabled-unselected-icon-opacity: 0.38;
    --mdc-switch-handle-height: 20px;
    --mdc-switch-handle-shape: 10px;
    --mdc-switch-handle-width: 20px;
    --mdc-switch-selected-icon-size: 18px;
    --mdc-switch-track-height: 14px;
    --mdc-switch-track-shape: 7px;
    --mdc-switch-track-width: 36px;
    --mdc-switch-unselected-icon-size: 18px;
    --mdc-switch-state-layer-size: 40px;
    --mdc-switch-selected-focus-state-layer-opacity: 0.12;
    --mdc-switch-selected-hover-state-layer-opacity: 0.04;
    --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
    --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
    --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
    --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
    --mat-slide-toggle-label-text-font: Roboto, sans-serif;
    --mat-slide-toggle-label-text-size: 14px;
    --mat-slide-toggle-label-text-tracking: 0.0178571429em;
    --mat-slide-toggle-label-text-line-height: 20px;
    --mat-slide-toggle-label-text-weight: 400;
    --mdc-switch-disabled-selected-handle-color: #424242;
    --mdc-switch-disabled-selected-icon-color: #fff;
    --mdc-switch-disabled-selected-track-color: #424242;
    --mdc-switch-disabled-unselected-handle-color: #424242;
    --mdc-switch-disabled-unselected-icon-color: #fff;
    --mdc-switch-disabled-unselected-track-color: #424242;
    --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
    --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mdc-switch-handle-shadow-color: black;
    --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mdc-switch-selected-icon-color: #fff;
    --mdc-switch-unselected-focus-handle-color: #212121;
    --mdc-switch-unselected-focus-state-layer-color: #424242;
    --mdc-switch-unselected-focus-track-color: #e0e0e0;
    --mdc-switch-unselected-handle-color: #616161;
    --mdc-switch-unselected-hover-handle-color: #212121;
    --mdc-switch-unselected-hover-state-layer-color: #424242;
    --mdc-switch-unselected-hover-track-color: #e0e0e0;
    --mdc-switch-unselected-icon-color: #fff;
    --mdc-switch-unselected-pressed-handle-color: #212121;
    --mdc-switch-unselected-pressed-state-layer-color: #424242;
    --mdc-switch-unselected-pressed-track-color: #e0e0e0;
    --mdc-switch-unselected-track-color: #e0e0e0;
    --mdc-switch-selected-focus-state-layer-color: #d81b60;
    --mdc-switch-selected-handle-color: #d81b60;
    --mdc-switch-selected-hover-state-layer-color: #d81b60;
    --mdc-switch-selected-pressed-state-layer-color: #d81b60;
    --mdc-switch-selected-focus-handle-color: #880e4f;
    --mdc-switch-selected-hover-handle-color: #880e4f;
    --mdc-switch-selected-pressed-handle-color: #880e4f;
    --mdc-switch-selected-focus-track-color: #f06292;
    --mdc-switch-selected-hover-track-color: #f06292;
    --mdc-switch-selected-pressed-track-color: #f06292;
    --mdc-switch-selected-track-color: #f06292;
}

/********** TOOLTIPS **********/

.tooltip-container {
    background-color: $blue1 !important;
    border-color: $blue1 !important;
    color: white !important;
}

/********** SCROLLBARS **********/

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 0;
    background-color: transparent;
}

::-webkit-scrollbar-track-piece {
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: $grey2;
    border-radius: 8px;
    z-index: 9999;
}

/********** ANIMATIONS **********/

@keyframes beat {
    to {
        box-shadow: inset 0 0 4px $blue1;
        color: $blue1;
    }
}

/*********** N4S ***********/

.n4s-content {
    hr {
        margin: 10px 0;
    }

    h3 {
        font-size: 20px;
        text-align: center;
        margin: 10px 0;
    }

    ul {
        margin-left: 20px;
    }

    .reportQuestionTitle {
        color: $green1;
        font-size: 16px;
        p {
            color: unset;
            font-style: italic;
            font-size: 14px;
            margin: 5px 0;
        }
        strong {
            font-size: 18px;
            font-weight: normal;
        }
    }
    .feedbacktext {
        color: unset;
    }
}
